import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { COMMON_TEXT, BUTTON, WIDGET_SETTING, CREATE_DASHBOARD_TEMPLATE } from '../../../../app/const/text-common';
import { ProcessLoadingService } from '../../../../app/services/loading.service';
import { Router } from '@angular/router';
import { LocalStorageHelper } from '../../../../app/_helper/local-storage.helper';
import { ROUTE_PATH } from '../../../../app/const/route-path';
import { Office } from '../../../../app/models/response/office.ro';
import { PivotTableConfig} from '../../../../app/models/response/widget.ro';
import { ButtonType, DataSourceType, DateFormat, DialogType, GraphType, InputType, LambdaStatus, PrimengDateFormat, ScreenMode, SearchType, WidgetSelectDataType } from '../../../../app/enum/common-enum';
import { ButtonItem, ListItemParams, ModalParam, SearchParams, WidgetGraphSetting } from '../../../../app/models/common-model';
import { filterByFinancialYearPeriod, getDayOfWeekOrMonthCurrent, getRange, makePivotConfig, PivotTableData, readTableConfig, getGraphTypeText, formatingDateTimeData } from '../../../../app/_helper/helper';
import { WidgetResultService } from '../../../../app/services/modules/widget-result.service';
import { DataSourceService } from '../../../../app/services/modules/data-source.service';
import { FilterConditionRequest, Widget, WidgetDetail, WidgetRequest, WidgetResultRequest } from '../../../../app/models/request/widget.dto';
import Utils from '../../../../app/util/utils';
import { WidgetService } from '../../../../app/services/modules/widget.service';
import { cloneDeep, join, orderBy, uniqBy } from 'lodash';
import { MstCommonService } from '../../../../app/services/modules/mstcommon.service';
import { ConfirmDialogComponent } from '../../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ColFilterGroup, customPeriod, DATA_SOURCE_DEFAULT_HEADERS, DATATYPE, DSCUSTOM, DSTARGET, FilterDateCurrent, FilterValueOption, FooterName, MstFilterDataTimeItem, SummaryColumnName } from '../../../../..../../app/const/const';
import { FOLDER_TYPE } from '../../../../app/models/response/folder.ro';
import { FolderService } from '../../../../app/services/modules/folder.service';
import { AuthenticationService } from '../../../../app/services/authentication.service';
import { firstValueFrom, Subject } from 'rxjs';
import { ErrorHandleService } from '../../../../app/services/error-handle.service';
import { StepFunctionService } from '../../../../app/services/modules/step-function.service';
import { ClearProcessService } from '../../../../app/services/clear-process.service';
import { DialogModalComponent } from '../../../../app/component/common/dialog-modal/dialog-modal.component';
import { DashboardService } from '../../../../app/services/modules/dashboard.service';
import { DashboardDetailModel } from '../../../../app/models/response/dashboard.ro';
import { v4 } from 'uuid';
import { OfficeAPIService } from '../../../../app/services/modules/office.service';
import * as moment from 'moment';
import { CorpMstService } from 'src/app/services/modules/corpmst.service';
import { SearchLogService } from 'src/app/services/modules/search-log.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { getDefaultFuntionFromSetting, getDSDefaultRequest, updateValueSettingForDFFuntion } from 'src/app/_helper/default-value-setting-helper';
import { DefaultValueSettingService } from 'src/app/services/modules/default-value-setting.service';

@Component({
    selector: 'pivot-create-widget-template',
    templateUrl: './create-widget-template.component.html',
    styleUrls: ['./create-widget-template.component.scss']
})
export class CreateWidgetTemplateComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() widget: any = null;
    @Input() keyLocalStorage: string = '';
    @ViewChild('period', { static: false }) periodOp: any;
    @ViewChild('splitter', { static: false }) splitter: ElementRef;

    COMMON_TEXT = COMMON_TEXT;
    CREATE_DASHBOARD_TEMPLATE = CREATE_DASHBOARD_TEMPLATE;
    WIDGET_SETTING = WIDGET_SETTING;
    BUTTON = BUTTON;
    isDspOfficeModal: boolean = false;
    isSavedGraphConfig: boolean = false;
    isDspDashboardModal: boolean = false;
    isDspDatasourceModal: boolean = false;
    isDspWidgetFolderModal: boolean = false;
    datasourceData: any = {};
    officeSelecteds: Array<Office> = [];
    isShowMsgExistDs: boolean = false;
    datasourceNm: string = '';
    dsType: number = 0;
    existDatasources: any[] = []
    dashboardSelecteds: Array<any> = [];
    dashboards: any[] = [];
    confirmModalData: any = {};
    isValidateFolder: boolean = false;
    isValidateOffice: boolean = false;
    isValidateDashboard: boolean = false;
    isValidatePeriod: boolean = false;
    isValidateDtsFolder: boolean = false;
    chartData: any | undefined;
    tableData: PivotTableData | undefined;
    cols: ListItemParams;
    rows: ListItemParams;
    value: ListItemParams;
    footers: ListItemParams;
    hiddens: ListItemParams;
    summaryColumns: ListItemParams;
    filteredTable: any[] = [];
    filterCondition: FilterConditionRequest = new FilterConditionRequest();
    widgetcd: string = '';
    config: PivotTableConfig = new PivotTableConfig()
    datasourceCd: string = '';
    // list folder widget
    folderWidgets: any[] = [];
    // folder save widget
    selectedFolder: any = null;
    selectedDtsFolder: any = null;
    folderDatasources: any[] = [];
    officeCds: string[] = [];
    dashboardSelected: any = null;
    offices: any[] = [];
    headers: any[] = [];
    dashboardFolders: any[] = [];
    currentHeaders: any[] = [];
    folderDbSelected: any = null;
    officeLabel: string = '';
    dateFormat: any = PrimengDateFormat.FULL_SHORT_DATE.toLocaleLowerCase();
    now: Date = new Date();
    startDate: Date = new Date();
    endDate: Date = new Date();
    holder: any = {}
    startMonth = 1;
    selectedRangeDate: any = {};
    dtsSelected: any = null;
    selectedPeriod: any = {}
    groupedPeriod: any = [];
    isLegendDisplay: boolean = true;
    isShowDlgConfirm: boolean = false;
    isAxisDisplay: boolean = true;
    isDisableRangeDate: boolean = true;
    isSelectAllOffice: boolean = false;
    isShowFolder: boolean = false;
    isSupporterAdmin: boolean = false;
    folderType = FOLDER_TYPE;
    isStackedChartSetting: boolean = false;
    isNotStackedChart: boolean = true;
    GraphType = GraphType;
    buttonType = ButtonType;
    graphConfig: any[] = [];
    chartTypeOptions: any[] = [{value: 0, title: "棒"}, {value: 1, title: '折れ線'}];
    showGraphSetting: Subject<void> = new Subject<void>();
    isShowChartData: boolean = true;
    widgetResult: any = null;
    panelSizes: any[] = [50,50];
    panelBottomHeight: number = 400;
    intervalIdList: any[] = [];
    isDisplayWarning: boolean = false;
    firstTimeCalling: boolean = true;
    warningMessage: string = '';
    colFilterStr : string = ColFilterGroup + '_';
    widgetDetails: any[] = [];
    periodName: string = '';
    optionSelected: any = null;
    chartType: any = null;
    selectedFolderRow: any = {};
    dashboardServiceData: any = {};
    // input textbox description params
    inputDesParams: any = {
        type: InputType.text,
        validate: false,
        borderFill: true,
        maxLength: 200,
        disabled: false
    };
    isOptionCreate: boolean = true;
    existDatasource: any = null;
    selectedExistDs: any[] = [];
    itemCharts: any[] = [];
     colSortState: string = 'none';
    /**
     * Render status of Pivot table
     * True: Keep origin of data(not merge cells)
     * False: Merge cells
    */
    isKeepOriginTable: boolean = false;
    isChangeOriginTable: boolean = false;

    filterArr: any[] = FilterValueOption.map(s => s.value);
    filterDateCurrent: any[] = FilterDateCurrent.map(c => c.value);
    SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;

    constructor( 
        private loadingService : ProcessLoadingService,
        private routerNavigate: Router,
        private datasourceService: DataSourceService,
        private widgetResultService: WidgetResultService,
        private widgetService: WidgetService,
        private mstService: MstCommonService,
        private modalService: DialogService,
        private folderService: FolderService,
        private authenticationService: AuthenticationService,
        private errorHandleService: ErrorHandleService,
        private stepFunctionService: StepFunctionService,
        private clearProcessService: ClearProcessService,
        private dashboardService: DashboardService,
        private officeService: OfficeAPIService,
        private corpMstService: CorpMstService,
        private searchLogService: SearchLogService,
        private defaultValueSettingService: DefaultValueSettingService,
        private saucerLogService: SaucerLogService
    ) {
        this.itemCharts = [
            { value: GraphType.BAR_CHART_HORIZONTAL, class: 'bar-chart-horizontal' },
            { value: GraphType.BAR_CHART_VERTICAL, class: 'bar-chart-vertical' },
            { value: GraphType.LINE_BAR_COMBINE_CHART, class: 'combo-chart' },
            { value: GraphType.LINE_CHART, class: 'line-chart' },
            { value: GraphType.DOUGHNUT_CHART, class: 'doughnut-chart' },
            { value: GraphType.NUMBER_CHART, class: 'number-chart' },
            { value: GraphType.PIE_CHART, class: 'pie-chart' },
            { value: GraphType.STACKED_BAR, class: 'stacked-bar-chart' },
            { value: GraphType.TABLE_CHART, class: 'table-chart' }
        ];



    }

    async ngOnInit() {
        this.loadingService.isLoading.emit(true);
        this.widget = this.widgetService.getWidgetDetail();
        if(this.widget) {
            await Promise.all([
                this.authenticationService.isAdminOrSupporter(),
                // lấy danh sách folder type widget
                this.getListFolderWidget(),
            ]).then(res => {
                const [isSupporterAdmin] = res;
                this.isSupporterAdmin = isSupporterAdmin;
            });
            if(this.widget?.charttype == GraphType.STACKED_BAR)
                this.isNotStackedChart = false;
            this.widget.widgetPosition = null;
            this.widget.width = null;
            this.widget.height = null;
            this.setWidgetSize()
            this.datasourceCd = !Utils.isNullOrEmpty(this.widget.datasourceCd) ?  this.widget.datasourceCd : this.widget.datasourcecd;
            if(this.widget.widgetdetails && this.widget.widgetdetails?.length > 0) {
                const { rows, columns, values, footers, summaryColumns, hiddens } = makePivotConfig(this.widget.widgetdetails)
                this.config = { rows: rows, columns: columns, values: values, footers: footers, summaryColumns: summaryColumns, hiddens: hiddens};
            }
            await this.getDatasourceAndWidgetData();
            if(this.widget.charttype == GraphType.LINE_BAR_COMBINE_CHART) {
                this.loadHeadersForGraphSetting();
            }

            //Log
            this.saucerLogService.action({}, { 
                action: SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_CREATION.VIEW
            });
        }
        else this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.LIST_ITEM_TEMPLATE)]);
    }

    selectAllOffice(isSelectAll: boolean) {
        this.isSelectAllOffice = isSelectAll;
        if(this.isSelectAllOffice) this.officeLabel = COMMON_TEXT.SELECT_ALL_OFFICE;
    }

    // lấy thông tin của datasource từ template để map data và thông tin widget để map
    async getDatasourceAndWidgetData() {
        await Promise.all([
            this.datasourceService.getDatasourceTemplate(this.datasourceCd),
            this.widgetResultService.getByWidgetCds(this.getRequestBodyData())
        ]).then(res => {
            const [dataSourceRes, widgetRes] = res;
            if(dataSourceRes.statuscode == 200) {
                this.dtsSelected = dataSourceRes.data;
                let period = dataSourceRes.data?.period;
                this.datasourceNm = dataSourceRes.data?.dsname;
                this.dsType = dataSourceRes.data?.dstype;
                this.selectedPeriod = period;
                if( this.selectedPeriod?.includes('~')) {
                    this.selectedPeriod = "-1";
                    let range = period?.split('~');
                    this.selectedRangeDate = {startDate: range[0], endDate: range[1]}
                    this.startDate = new Date(range[0]?.toString()?.trim());
                    this.endDate = new Date(range[1]?.toString()?.trim());
                    this.periodName = COMMON_TEXT.CUSTOMIZATION;
                    this.startDate = new Date(this.selectedRangeDate.startDate);
                    this.endDate = new Date(this.selectedRangeDate.endDate);
                }
                this.setRangeDate();
            }
            // mapdata cho widget
            let widgetResultData: any[] = [];
            if(widgetRes.statuscode == 200) {
                widgetResultData = widgetRes.data || []; 
                this.tableData = { table: widgetResultData[0]?.result?.table, config: this.config }
                this.chartData = widgetResultData[0]?.result?.chart;
                this.widget.x = 0;
                this.widget.y = 0;
                this.widget.widgetConfig = this.widget.widgetconfigs; 
                if(this.widget.widgetConfig && !this.isChangeOriginTable)
                    this.isKeepOriginTable = readTableConfig(this.widget.widgetConfig);
                this.widget.widgetdetails = this.widget.widgetdetails;
                let widgetResult = widgetResultData.filter((x:any) => x.widgetcd == this.widget.widgetcd).pop();
                if(widgetResult?.result) {
                    this.widgetResult = cloneDeep(widgetResult?.result);
                    if(this.widgetResult?.graphConfigs?.length > 0) {
                        this.isStackedChartSetting = this.widgetResult?.graphConfigs[0].isStackedChartSetting || false;
                    }
                }
                this.widget = cloneDeep(this.widget);
            }
        });
    }

    // show dialog dashboard
    showDialogDashboardSelect() {
        this.isDspDashboardModal = true;
        this.dashboardServiceData = {
            dashboards: this.dashboards,
            dashboardSelected: this.dashboardSelected,
            dashboardFolders: this.dashboardFolders,
            folderDbSelected: this.folderDbSelected
        }

        //Log
        this.saucerLogService.action({}, { 
            action: SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_CREATION.WIDGET_DISPLAY_DESTINATION
        });
    }

    //Show dialog DATASOURCE
    showDialogDatasource() {
        const data = {
            dashboardSelecteds: this.dashboardSelecteds,
            existDatasources: this.existDatasources,
            isOptionCreate: this.isOptionCreate,
            isShowMsgExistDs: this.isShowMsgExistDs,
            selectedPeriod: this.selectedPeriod,
            selectedRangeDate: this.selectedRangeDate,
            datasourceCd: this.datasourceCd,
            datasourceNm: this.datasourceNm,
            isSupporterAdmin: this.isSupporterAdmin,
            officeSelecteds: this.officeSelecteds,
            folderList: this.folderDatasources,
            selectedDtsFolder:  this.selectedDtsFolder,
            selectedExistDs: this.selectedExistDs
        }
        this.datasourceData = data;
        this.isDspDatasourceModal = true;

        //Log
        this.saucerLogService.action({}, { 
            action: SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_CREATION.DATASOURCE_SAVE_DESTINATION
        });
    }

    showDialogSelectWidgetFolder() {
        this.isDspWidgetFolderModal = true;
    }

    // close dashboard modal
    closeDashboardModal(data: any) {
        this.dashboardFolders = data;
        this.isDspDashboardModal = false;
    }

    closeWidgetFolderModal(data: any) {
        this.isDspWidgetFolderModal = false;
    }


    closeDataSourceModal(data: any) {
        this.isDspDatasourceModal = false;
        if(!data.isCancel) {
            this.datasourceNm = data.datasourceNm;
            this.isOptionCreate = data.isOptionCreate;
            this.isValidateDtsFolder = false;
            this.isShowMsgExistDs = false;
            this.existDatasource = data.existDatasource;
            this.selectedDtsFolder = data.selectedDtsFolder;
            this.selectedExistDs = data.selectedExistDs;
        }
        this.folderDatasources = data.folderList
    }

    getPeriodsData(periodMst: any[]) {
        if(!periodMst || periodMst.length ==0) return;
        // lưu list period của datasource
        let periodList: any[] = [];
        // push custom period vô list
        periodList.push(...cloneDeep(customPeriod));
        // push list period mst vô period list
        periodList.push(...periodMst);
        // nếu data source là datasource seikuy thì set period list theo dts seikuy
        if(this.dsType === DataSourceType.SEIKUY){
            // loại bỏ những period không thuộc seikuy
            let seikuyPeriods  = periodList?.filter((x: any) => x.value != "-1" && x.value != MstFilterDataTimeItem.ThisYear && x.value != MstFilterDataTimeItem.Today && x.value != MstFilterDataTimeItem.ThisWeek);
            this.groupedPeriod = cloneDeep(seikuyPeriods);
        }
        // set list period đối với các dts không phải seikuy
        else this.groupedPeriod = cloneDeep(periodList);
        // map label cho period hiển thị theo label seikuy hay dts không phải seikuy
        this.groupedPeriod.map((group:any) => group.items.map((item:any) => {
           // nếu datasource là seikuy thì map label = contentseikuy
            if(this.dsType === DataSourceType.SEIKUY){
              item.label = item.contentseikuy ??  item.label;
            }
            // nếu dts không phải seikuy
            else item.label = item.content ??  item.label;
        }));
        // nếu period khác custom -1 là period custom
        if( this.selectedPeriod != "-1") {
            this.groupedPeriod.forEach((group: any) => {
                let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
                if(selectedItem) {
                    // gán range Date là sdate và edate của datasource seikuy
                    if(this.dsType === DataSourceType.SEIKUY){
                        this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
                    }
                    // gán sdate, edate cho các loại datasource còn lại
                    else{
                        this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
                    }
                }
           });
        }
    }

    async ngAfterViewInit() {
        // set mặc định period theo bên datasource-create
        await Promise.all([
            this.officeService.getAllOfficeService(),
            this.corpMstService.getAll()
        ]).then(async res => {
            const [offices, corpMstRes] = res;
            this.offices = offices;
            if(corpMstRes.statuscode == 200) {
                let monthSetting = corpMstRes.data?.find((x:any) => x.contentcd == "0001");
                this.startMonth = +(monthSetting?.value || 1);
                let filterBodyRequest = {
                    startmonth: this.startMonth
                }
                let periodMst = await this.mstService.getMstFilter(filterBodyRequest) as any[];
                this.getPeriodsData(periodMst);
            }
        });
        this.setRangeDate();
        this.loadingService.isLoading.emit(false);
    }

    async getListFolderWidget() {
        this.folderService.getListFolderHavePermissionEdit(FOLDER_TYPE.Widget.toString(), this.isSupporterAdmin).then((resutl: any) => {
            if (resutl.statuscode == 200) {
                this.folderWidgets = resutl?.data || [];
            }
        });
    }


    showDialogSelectFolder() {
        this.isShowFolder = true;
    }

    // on selected dashboard
    selectedDashboardData(data: any) {
        if(!data) return;
        this.dashboards = data?.dashboards || [];
        this.dashboardSelected = data?.dashboardSelected;
        this.dashboardFolders = data?.dashboardFolders || [];
        this.folderDbSelected = data?.folderDbSelected;
        this.isValidateDashboard = false;
    }

    selectedWidgetFolderData(data: any) {
        if(data) {
            this.folderWidgets = data.folders || [];
            this.selectedFolder = data.selectedFolder;
            this.isValidateFolder = false;
        }
        this.isDspWidgetFolderModal = false;
    }

    //#region handle exsit datasources
    async getListExistDatasource() {
        if(this.officeSelecteds.length == 0 || this.selectedPeriod == null ) {
            return
        };

        this.loadingService.isLoading.emit(true);

        const officeCds = this.officeSelecteds?.map(of => of.officeCd).sort((a,b) => {
        return Number(a) - Number(b)
        });

        const officeIdLst = join(officeCds, "-");
        const startDate = this.selectedRangeDate.startDate;
        const endDate = this.selectedRangeDate.endDate;
        const period = this.getPeriodData();
        const data: any = {
            period: period, 
            officeIdLst: officeIdLst, 
            datasourceCdTemplate: this.datasourceCd, 
            startDate: startDate, 
            endDate: endDate
        }
        // call api lấy danh sách datasource exist đã tạo bên user
        const res =  await this.datasourceService.getExistDatasources(data);
        if (res.statuscode === 200 && res.data?.length) {
            this.isShowMsgExistDs = true;
            this.existDatasources = res.data;
            this.loadingService.isLoading.emit(false);
        } else {
            this.isShowMsgExistDs = false;
            this.isOptionCreate = true;
            this.inputDesParams.disabled = false;
            this.existDatasources = [];
            this.loadingService.isLoading.emit(false);
        }
    }

    // back page
    backPage() {
        //Log
        this.saucerLogService.action({}, { 
            action: SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_CREATION.GO_BACK
        });

        let widgetFiltersData = this.widgetService.getListWidgetTemplateFilter();
        if(widgetFiltersData) {
            this.widgetService.setListWidgetTemplateFilter( widgetFiltersData?.widgetFilters,  widgetFiltersData?.searchKeys);
        }
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.LIST_ITEM_TEMPLATE)]);
    }

    ngOnDestroy(): void {
		let pathname = window.location.pathname;
		if (!pathname?.includes(ROUTE_PATH.LIST_ITEM_TEMPLATE) &&
		  !pathname?.includes(ROUTE_PATH.CREATE_WIDGET_TEMPLATE)) {
		  this.widgetService.setListWidgetTemplateFilter([], []);
		}
	}

    // on show dialog selected office
    showDialogSelectOffice() {
        this.isDspOfficeModal = true;

        //Log
        this.saucerLogService.action({}, { 
            action: SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_CREATION.SELECT_BUSINESS
        });
    }

    // on close office modal
    closeOfficeModal() {
        this.isDspOfficeModal = false;
    }

    // on change chart type
    async onChangeChartType(type: any) {
        if(!this.widget) return;

        const oldLog = {
            'chartType': getGraphTypeText(parseInt(this.widget.charttype))
        };
        const newLog = {
            'chartType': getGraphTypeText(parseInt(type))
        };

        this.chartData = null;
        this.widget.isLoadWidgetResultComplete = false;
        this.widget.type = parseInt(type);
        this.widget.charttype = this.widget.type;
        if(this.widget.charttype == GraphType.LINE_BAR_COMBINE_CHART) 
            this.loadHeadersForGraphSetting();
        await this.getWidget(); 
        if(this.widget.charttype == GraphType.STACKED_BAR) this.isNotStackedChart = false;
        else this.isNotStackedChart = true;
        this.widget.isLoadWidgetResultComplete = true;

        //Log
        this.saucerLogService.action({
            content: JSON.stringify({
                old: oldLog,
                new: newLog
            })
        }, { 
            action: SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_CREATION.DISPLAY_FORMAT
        });
    }

    // on selected office
    async selectedOffice(data: any) {
        if(!data) return;
        this.officeSelecteds = data;
        this.isValidateOffice = false;
        this.officeCds = this.officeSelecteds.map(o => o.officeCd).sort((a,b) => {
            return Number(a) - Number(b)
        });
        this.setOfficeLabel(data, this.isSelectAllOffice);
        await this.getListExistDatasource();
    }

    setOfficeLabel(offices: any[], isSelectAll : boolean) {
        if(offices.length == 1) this.officeLabel = offices[0].officeNm? offices[0].officeNm 
                            : this.offices.find(o => o.officeCd == offices[0])?.officeNm;
        else if(isSelectAll) this.officeLabel = COMMON_TEXT.SELECT_ALL_OFFICE;
        else this.officeLabel = offices.length + COMMON_TEXT.SELECTED_OFFICE;
    }

    validateData() {
        this.isValidateFolder = false;
        this.isValidateDashboard = false;
        this.isValidateOffice = false;
        this.isValidateDtsFolder = false;
        if(this.isCanSave(true)) {
            if(!this.selectedFolder) this.isValidateFolder = true;
            if(!this.dashboardSelected) this.isValidateDashboard = true;
            if(this.officeCds.length == 0) this.isValidateOffice = true;
            if(!this.selectedDtsFolder) this.isValidateDtsFolder = true;
            return true;
        }
        else return false;
    }

    // on save widget data
    async saveWidget() {
        const api = this.widgetService.getWidgetTemplate(this.widget.widgetcd);
        api
        .then(async res => {
            let data = res.data as any[]
            if(res.statuscode != 200) {
                this.errorHandleService.backURLSub.next(ROUTE_PATH.WIDGET_TEMPLATE_LIST)
            }
            data.map((item: any) => item.datasourcecd = (item.datasourcecd == "DS-CUSTOM" || item.datasourcecd == "DS-TARGET")   ? item.datasourcecd : this.datasourceCd);
            this.widget.id = null
            this.widgetDetails = data?.map(x =>  new WidgetDetail(x))
            this.widgetDetails.filter((x: any) => x.columnname?.includes(ColFilterGroup)).map(x => {
                let columnMap = this.widgetDetails?.find(s => s.columnname == x.columnname?.replace(this.colFilterStr, ''));
                if(columnMap) x.displayname = columnMap.displayname;
            });
        });

        await this.createDataFromTemplate(false);
    }

    async onshowDialogConfirm() {
        if(this.validateData()) return;
        let startDate = this.selectedRangeDate.startDate?.trim();
        let endDate = this.selectedRangeDate.endDate?.trim();
        this.confirmModalData = {
            officeLabel: this.officeLabel,
            periodName: `${moment(startDate).format(DateFormat.JP_FULL_LONG_DATE)} ~ ${moment(endDate).format(DateFormat.JP_FULL_LONG_DATE)}` + '【'+ this.periodName + '】',
            folderDatasource: this.isOptionCreate? (this.selectedDtsFolder?.name || '') : (this.existDatasource?.foldername || ''),
            datasourceName: this.datasourceNm,
            dashboardName: this.dashboardSelected?.name,
            widgetName: this.widget?.widgetName,
            forderWidget: this.selectedFolder?.folderName,

        }
        this.isShowDlgConfirm = true;
        await this.insertSearchLog(true);

        //Log
        this.saucerLogService.action({}, { 
            action: SAUCER_LOG_ACTION.MENU_TEMPLATE.WIDGET_CREATION.ADD
        });
    }

    // save datasource, widget and dashboard setting.
    async confirmSaveData(data: boolean) {
        this.isShowDlgConfirm = false;
        if(data == null) return;
        else {
            if(!data) await this.saveWidget();
            else await this.createDataFromTemplate(true);
        }
    }

    checkIfStopInterval(screenCode: any, intervalId: any): boolean {
        let result: boolean = false;
        if(this.firstTimeCalling) {
          this.firstTimeCalling = false;
          this.clearProcessService.setScreenCode(screenCode);
        }
        const holderCode = this.clearProcessService.getScreenCode();
        if(holderCode != screenCode) {
          clearInterval(intervalId);
          this.removeIntervalIdToList(intervalId);
          result = true;
        }
    
        return result;
    }

    // get period data from range date
    getPeriodData() {
        let period = ''
        let startDate = this.selectedRangeDate.startDate?.trim();
        let endDate = this.selectedRangeDate.endDate?.trim();
        // period is custom
        if(this.selectedPeriod === '-1'){
          period = `${startDate} ~ ${endDate}`
        }
        else {
          period = this.selectedPeriod;
        }
        return period;
    }

    // lấy request params khi tạo template
    getTemplateCreateRequest() {
        let period = this.getPeriodData();
        return {
            dsstructcd: this.datasourceCd,
            widgetcd: this.widget.widgetcd,
            foldercd:  this.selectedDtsFolder ? this.selectedDtsFolder?.folderCd : this.selectedFolder?.id,
            startdate: this.selectedRangeDate.startDate,
            enddate: this.selectedRangeDate.endDate,
            officecds: this.officeCds,
            dsName: this.datasourceNm,
            period: period,
            dsname: this.datasourceNm
        }
    }

    // check condition before save
    isCanSave(isCreateDashboard: boolean) {
        if(!this.selectedFolder || this.officeCds.length == 0 || (isCreateDashboard &&  !this.dashboardSelected)|| this.isValidatePeriod 
        || (!this.selectedDtsFolder && this.isOptionCreate))
            return true;
        return false;
    }

    // tạo data từ template (datasource, widget, widget result, dashboard detail)
    async createDataFromTemplate(isCreateWidget: boolean = false) {
        let datasourceTemplateRequest = this.getTemplateCreateRequest();
        this.loadingService.isLoading.emit(true);
        if (this.isOptionCreate) { 
            this.datasourceService.checkTitleExist(datasourceTemplateRequest).then(ttlRes =>{
            if(ttlRes.statuscode != 200 || !ttlRes.data) {
                this.loadingService.isLoading.emit(false);
                this.showDialogError();
                return;
            }
            else if(ttlRes.statuscode === 200 && ttlRes.data) {
                // sao chép datasource từ template qua user
                this.datasourceService.cloneDataSourceTemplate(datasourceTemplateRequest).then(templateRes => {
                    if (templateRes.statuscode === 200) {
                        const screenCodeFor2rdProcess = 1 + Math.random();
                        let intervalId = setInterval(() => {
                        if(this.checkIfStopInterval(screenCodeFor2rdProcess, intervalId)) {
                            return;
                        }
                        this.stepFunctionService.checkStatus(templateRes.data.lambdaresponse.executionarn).then(resLamda => {
                            if(resLamda.statuscode && resLamda.statuscode == 500) {
                            clearInterval(intervalId);
                            this.removeIntervalIdToList(intervalId);
                            }
                            if (resLamda.data.status === LambdaStatus.SUCCEEDED) {
                            clearInterval(intervalId);
                            this.removeIntervalIdToList(intervalId);
                            this.datasourceService.getResultOnS3(templateRes.data.dsstructcd).then(async res => {
                                let resultData = JSON.parse(res.data);
                                if (res.statuscode == 200 && resultData.status === true) {
                                    this.datasourceCd = templateRes.data.dsstructcd;
                                    await this.insertDataFromTemplate(isCreateWidget);
                                }
                            })
                            } else if (resLamda.data.status != LambdaStatus.RUNNING && resLamda.data.status != "") {
                            clearInterval(intervalId);
                            this.removeIntervalIdToList(intervalId);
                            }
                        })
                        }, 5000);
                        this.addIntervalIdToList(intervalId);
                    }
                    // else
                    //     this.errorHandleService.backURLSub.next(ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE + "/4");
                    });
                }
                else {
                    this.showDialogError();
                }
            }).catch(ex => {
                this.loadingService.isLoading.emit(false);
            });
        }
        else {
            // tạo data từ data template
            await this.insertDataFromTemplate(isCreateWidget);
        }
    }

    // thêm mới data từ template 
    async insertDataFromTemplate(isCreateWidget: boolean = false) {
        // nếu isCreateWidget = true thì chạy theo luồng cũ navigate ra màn hình widget-setting rồi sau khi lưu sẽ navigate về dashboard setting
        if(isCreateWidget) {
            this.graphConfig = this.setGraphConfig();
            let dssCd: string = this.isOptionCreate ? this.datasourceCd : this.existDatasource?.dsstructcd;
            let useExistDs: boolean = this.isOptionCreate? false : true;
            this.widgetService.pass({ widgetCd: undefined, folderCd: this.selectedFolder?.folderCd || '', mode: ScreenMode.ADD });
            this.widgetService.setCreatingByTemplate({ widgetcdTemplate: this.widget?.widgetcd, dsStructCd: dssCd || '', useExistDs: useExistDs , lstOffices: this.officeCds, 
                name: this.widget?.widgetName, type: this.widget?.charttype, desc: this.widget?.widgetdesc, graphconfig: this.graphConfig});
            this.dashboardSelected.dashboardCd = this.dashboardSelected.value;
            // set setDashboardItem để lấy được thông tin bên màn hình dashboard setting
            this.dashboardService.setDashboardItem(ScreenMode.EDIT, cloneDeep(this.dashboardSelected));
            this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
        }
        // nếu isCreateWidget = false thì sẽ tạo widget, widget result và gắn widget vừa tạo vào dashboard đây là luồng mới
        else {
            let dsscd = this.isOptionCreate ? this.datasourceCd : this.existDatasource?.dsstructcd || '';
            await this.mapTotalDay(dsscd);
            await this.createWidget(dsscd)
            .then(async res => {
                if(res.statuscode == 200) {
                    let newWidgetCd = res.data;
                    // insert widget result
                    this.widgetResultService.createWidgetResultFromWidgetCdAndDatasourceCd(res.data, dsscd).then( async(res) => {
                        if(res.statuscode == 200) {
                            // insert dashboard setting
                            await this.createDashboardSetting(newWidgetCd).then((result: any) => {
                                if(result.statuscode == 200) {
                                    // pass thông tin widget qua màn hình dashboard-setting để bên dashboard-setting pick về có data.
                                    this.widgetService.pass({ widgetCd: newWidgetCd, folderCd: this.selectedFolder?.folderCd, mode: ScreenMode.PREVIEW , type: this.widget?.chartType,
                                        description: this.widget?.widgetdesc, sortcoltype: this.widget?.sortcoltype, name: this.widget?.widgetName });
                                    this.loadingService.isLoading.emit(false);
                                    this.dashboardService.setDashboardItem(ScreenMode.PREVIEW , cloneDeep(this.dashboardSelected));
                                    this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_DETAIL), result.data, ScreenMode.PREVIEW]);
                                }
                            });
                        }
                    });

                }
            })
            .catch(() => this.loadingService.isLoading.emit(false))
            .finally();
        }
    }

    // thêm IntervalId vào  intervalId List
    addIntervalIdToList(id: any) {
        if(this.intervalIdList.includes(id)) return;
        this.intervalIdList.push(id);
        this.clearProcessService.setIntervalId(id);
    }

    // open dialog success
    openDialogSuccess(dialogType: DialogType) {
        this.modalService.open(ConfirmDialogComponent, {
          data: {
            dialogType: dialogType,
          }
        });
    }

    isFilterDate() {
        return this.widget.widgetdetails?.filter((s:any) => s.datatype == DATATYPE.Date && FilterDateCurrent.filter(ft => ft.value == s.filtertype)?.length > 0)?.length > 0 ? true : false;
    }
    
    getDateRangeByPeriod(periods: any[]) {
        this.widget.widgetdetails = this.widget.widgetdetails.map((w:any) => {
            if(w.filtertype && w.filtervalue && !w.delflg && w.datatype == DATATYPE.Date) {
                // update date range for date current
                let groupFilters = w.filtertype?.split('-');
                let periodArr = this.selectedPeriod?.split('-');
                if(groupFilters.length > 1) {
                    let findPeriod = periods?.find(s=>s.value == periodArr[0]);
                    let rangeDate: any = null;
                    if(findPeriod) {
                        if(this.filterArr.includes(w.filtertype)) {
                            rangeDate = filterByFinancialYearPeriod(groupFilters[1], this.groupedPeriod);
                            w.filtervalue = rangeDate;
                        }
                        else if(this.filterDateCurrent.includes(w.filtertype)) {
                            let dateCurrent = getDayOfWeekOrMonthCurrent(groupFilters[1]);
                            rangeDate = findPeriod.items?.find((p: any) => p.value == this.selectedPeriod || '');
                            w.filtervalue = moment(this.dsType == DataSourceType.SEIKUY? rangeDate?.startdateseikuy : rangeDate?.startdate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(dateCurrent?.end).format(DateFormat.FULL_SHORT_DATE)
                        }   
                    }
                    else {
                        rangeDate = this.selectedPeriod.split('~');
                        if(rangeDate.length > 1 && this.filterDateCurrent.includes(w.filtertype)) {
                            let dateCurrent = getDayOfWeekOrMonthCurrent(groupFilters[1]);
                            w.filtervalue = moment(rangeDate[0]).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(dateCurrent?.end).format(DateFormat.FULL_SHORT_DATE)
                        }
                        else {
                            w.filtervalue = getRange(groupFilters[1], this.selectedPeriod, this.groupedPeriod, this.dsType);
                        }
                    }
                }
            }
          return w;
        });
      }
      
    // create widget
    async createWidget(datasourceCd: string) {
        let widgetDetails: any[] = cloneDeep(this.widget.widgetdetails?.filter((w: any) => !w.delflg)) || [];
        widgetDetails.forEach((item: any) => {
            if( item.datasourcecd != DSCUSTOM && item.datasourcecd != FooterName &&  item.datasourcecd != SummaryColumnName && item.datasourcecd != DSTARGET)
                item.datasourcecd = datasourceCd;
        });
        this.widget.widgetdetails = widgetDetails.map((x:any) =>  new WidgetDetail(x));;
        this.widget.id = undefined;
        this.widget.chartType = this.widget.type;
        this.widget.widgetcd = undefined;
        this.widget.foldercd = this.selectedFolder.folderCd;
        this.widget.folderCd = this.selectedFolder.folderCd;
        this.graphConfig = this.graphConfig || [];
        this.graphConfig = this.setGraphConfig();

        if(this.widget?.widgetConfig?.targetconfig) {
        let settingValueConfig =  {
          id: this.widget?.widgetConfig?.id,
          targetTable: this.widget?.widgetConfig?.targetconfig != "" ? JSON.parse(this.widget.widgetConfig.targetconfig) : null,
        }
        
        settingValueConfig = await this.CreateDsDefaultFromTemplate(this.officeCds, settingValueConfig, datasourceCd);
        if(this.widget.dsdefaultcd) {
          this.widget.widgetConfig.targetconfig = JSON.stringify(await this.widgetService.getTarConfigByIndex(settingValueConfig.targetTable, datasourceCd));
        }else {
          this.widget.widgetConfig.targetconfig = JSON.stringify(settingValueConfig.targetTable);
        }
        
      }
      


        let widgetconfig = {
            id: this.widget?.widgetConfig ? this.widget.widgetConfig?.id : "",
            targetConfig: this.widget?.widgetConfig ? this.widget.widgetConfig?.targetconfig : "",
            graphConfig: JSON.stringify(this.graphConfig)
        };

        if(this.isFilterDate()) this.getDateRangeByPeriod(this.groupedPeriod);

        const request: WidgetRequest = {
            widget: this.mapWidgetModel(),
            widgetdetails: this.widget.widgetdetails?.filter((x:any) => !x.delflg || x.id),
            widgetconfig:  { ...widgetconfig }
        }
        // insert widget
        return this.widgetService.postWidget(request)
    }
    mapWidgetModel() {
        let widget: Widget = new Widget();
        widget.charttype = this.widget.chartType;
        widget.id = this.widget.id;
        widget.widgetcd = this.widget.widgetcd;
        widget.widgetname = this.widget.widgetName;
        widget.widgetdesc = this.widget.widgetdesc;
        widget.foldercd = this.widget.folderCd;
        widget.dsstructcd = this.widget.datasourceCd;
        widget.sortcoltype = this.widget?.sortcoltype;
        widget.dsdefaultcd = this.widget?.dsdefaultcd;
        widget.issettingtarget = this.widget?.issettingtarget;
        return widget;
    }




    // xoá IntervalId ra khỏi danh sách
    async CreateDsDefaultFromTemplate(lstOfficesSelect:any[], settingValueConfig: any, datasourceCdTemplate: any){
      let offices = lstOfficesSelect?.join("-");
      let listValueDefaultSetting:any[] =[];
      var resDefaultValueSettingList = await this.defaultValueSettingService.getAll();
      if (resDefaultValueSettingList && resDefaultValueSettingList.statuscode == 200) {
        listValueDefaultSetting =  (resDefaultValueSettingList.data ?? []).filter((df:any) => df?.defaultValueSetting?.invalidflg == false);
      }

      this.widget.dsdefaultcd = "";
      let paramDefaultFuntion = getDefaultFuntionFromSetting(settingValueConfig);
      if(paramDefaultFuntion.length == 0) return settingValueConfig;
      
      let dsDefaultRequest = getDSDefaultRequest(paramDefaultFuntion,true, null, offices);
  
      let request = { 
        dsstructcd: datasourceCdTemplate,
        dsdefaultcd: null,
        lstcondition: dsDefaultRequest
      }
      let res = await this.defaultValueSettingService.createDsDefaultFromTemplate(request);

      if(res.statuscode == 200) {
        if(settingValueConfig && settingValueConfig.targetTable && settingValueConfig.targetTable.length > 0){
          settingValueConfig = updateValueSettingForDFFuntion(settingValueConfig, res, true);
        }
        
        let requestInsertDf = {
          settingValueConfig: settingValueConfig,
          listValueDefaultSetting: listValueDefaultSetting,
          dsstructcd: datasourceCdTemplate,
          dsdefaultcd: null,
          isPreview: false
        }
        let repseInsert =  await this.defaultValueSettingService.updateOrPrevivewDsDefault(requestInsertDf);
        this.widget.dsdefaultcd = repseInsert?.dsdefaultcd;
      }
      return settingValueConfig;
    }

    // xoá IntervalId ra khỏi danh sách
    removeIntervalIdToList(id: any) {
        this.intervalIdList = this.intervalIdList.filter(e => e != id);
        this.clearProcessService.removeIntervalIdList(id);
        this.firstTimeCalling = true;
    }

    // change date value 
    async onChangeDate(value: any, type: any) {
        let dateValue = moment(value).format(DateFormat.FULL_DATE_HOUR);
        if(type == 1) {
            this.selectedRangeDate.startDate = dateValue;
        }
        else {
            this.selectedRangeDate.endDate = dateValue;
        }
        this.setValidatePeriod();
        // áp dụng cho trường hợp custom period lấy danh sách datasoủce exist
        if(this.selectedPeriod === '-1'){
           await this.getListExistDatasource();
        }
    }

    // set range date theo period select
    setRangeDate() {
        if(this.selectedPeriod && this.selectedPeriod != '-1'){
            this.groupedPeriod.forEach((group: any) => {
                let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
                if(selectedItem){
                    this.optionSelected = selectedItem;
                    // nếu là datasource seikuy thì gán data period seikuy
                    if(this.dsType === DataSourceType.SEIKUY){
                        this.periodName = selectedItem.contentseikuy;
                        this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
                    }
                    // datasource không phải seikuy
                    else{
                        this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
                        this.periodName = selectedItem.content;
                    }
                    this.startDate = new Date(this.selectedRangeDate.startDate);
                    this.endDate = new Date(this.selectedRangeDate.endDate);
                }
            });
            // tiến hành set validate cho period 
           this.setValidatePeriod();
        }
    }

    // set validate period
    setValidatePeriod() {
        if(this.selectedPeriod === '-1') { 
        const diffDays = Math.round(moment(this.endDate, 'YYYY-MM-DD').startOf('day').hour(23).minute(59).seconds(59).diff(moment(this.startDate, 'YYYY-MM-DD').startOf('day'), 'days', true));
        this.isValidatePeriod = diffDays > 365? true : false;
        }
        else this.isValidatePeriod = false;
    }

    // create dashboard setting
    async createDashboardSetting(widgetcd: string) {
        this.loadingService.isLoading.emit(true);
        if(!this.dashboardSelected) return;
        let dashboardcd = this.dashboardSelected.dashboardCd;
        let widgets: any[] = [];
        // lấy dashboard data
        let dashboardInfo: any[] = await this.dashboardService.getSettingDetail(dashboardcd) || [];
        if(dashboardInfo?.length > 0) {
            // lấy list widget trong dashboard setting
            widgets = dashboardInfo[0].setting || [];
        }

        let widgetChanges: DashboardDetailModel[] = [];

        let x = 0, y = 0;
        if (widgets.length > 0) {
            // lấy widget cuối cùng trong danh sách setting
            const lastWiget = widgets.reduce((max, current) => {
                const maxPos = JSON.parse(max.widgetPosition);
                const currentPos = JSON.parse(current.widgetPosition);
                if (currentPos.y > maxPos.y || (currentPos.y === maxPos.y && currentPos.x > maxPos.x)) {
                  return current;
                }
                return max;
            });

            // toạ độ của widget cuối cùng
            let lastWidgetPosition = JSON.parse(lastWiget?.widgetPosition);
            
            // set lại toạ độ dựa theo tạo độ cuối cùng
            // --|------------|  |------------ |
            // --|  x:0;y:0   |  |  x:14;y:0   |
            // --|------------|  |------------ |
            // --|  x:0;y:7   |  |  x:14;y:7   |
            // --|------------|  |------------ |
            // --|  x:0;y:14  |  |  x:14;y:14  |
            // --|------------|  |------------ |
            // --|  x:0;y:21  |  |  x:14;y:21  |
            // --|------------|  |------------ |
            if (lastWidgetPosition.x == 0 && lastWidgetPosition.y == 0) {
                x = 14;
            } else if (lastWidgetPosition.x > 0 && lastWidgetPosition.y > 0 || lastWidgetPosition.x > 0 && lastWidgetPosition.y == 0) {
                x = 0;
                y = lastWidgetPosition.y + 7;
            } else if (lastWidgetPosition.x == 0 && lastWidgetPosition.y > 0) {
                x = 14;
                y = lastWidgetPosition.y;
            }
        }
        // set vị trí cho widget trong dashboard-setting
        let widgetPosition = JSON.stringify({
            x: x,
            y: y,
            rows: 7,
            cols: 14,
            width: 500,
            height: 400 
        })

        widgetChanges.push({
            id: v4(),
            dashboardcd: dashboardcd,
            widgetcd: widgetcd,
            // set toạ độ y cho widget thêm mới
            widgetposition: widgetPosition,
            widgetnew: true
        });

        // tạo dashboard setting params
        let dashboardSetting: any = {
            listDataChange: widgetChanges,
            listRemove: [],
            featureDuplicates: [],
            isduplicate: false,
            dashboard: {
              dashboardCd: this.dashboardSelected.dashboardCd,
              dashboarddesc: this.dashboardSelected.description,
              dashboardname: this.dashboardSelected.name,
              foldercd: this.dashboardSelected.folderCd,
              publicCd: this.dashboardSelected.publicCd
            }
        };
        // thêm widget vô dashboard setting
        let newDashboard = this.dashboardService.insertOrUpdateDbDetail(dashboardSetting);
        return newDashboard;
    }

    onClickPeriod(event: any) {
        this.periodOp.hide();
    }
    // selected period
    async onSelectedPeriod(option: any) {
        if(option?.value == '-1') {
            this.selectedPeriod = '-1';
            this.isDisableRangeDate = false;
            this.periodName = option.label;
        }
        else {
            this.periodName = this.dsType === DataSourceType.SEIKUY? option?.contentseikuy:  option?.content;
            this.isDisableRangeDate = true;
            this.selectedPeriod = option.value;
            this.setRangeDate();
        }
        await this.getListExistDatasource();
        this.periodOp.hide();
    }

    // đóng dialog folder
    closeFolderModal() {
        this.isShowFolder = false;
    }

    // get widget data
	async getWidget() {
        if(!this.widget) return;
        await this.getByWidgetCds();
	}

    // hoàn thành render chart
    onRenderChartComplete(){
        this.loadingService.isLoading.emit(false);
    }

    // change widget name event
    onChangeWidgetName(event: any) {
       if(!this.widget) return;
       this.widget.name = event.target?.value || '';
       this.widget.widgetName = event.target?.value || ''
    }

    onChangeWidgetDesc(event: any) {
        if(!this.widget) return;
        this.widget.widgetDesc = event?.target?.value || ''
    }

    // thay đổi size của textArea theo chiều dài của widget desc
    resizeTextArea(id: string) {
        const textarea: any = document.getElementById(id);
        if(!textarea) return;
        textarea.style.height = '34px';
        const newHeight = Math.min(textarea.scrollHeight, 50) < 40 ? 34 : Math.min(textarea.scrollHeight, 50); 
        textarea.style.height = `${newHeight}px`;
    }

    onStartRenderChart(){
        this.loadingService.isLoading.emit(true);
    }

    loadHeadersForGraphSetting() {
        this.graphConfig = this.widget?.widgetconfigs?.graphconfig ? JSON.parse(this.widget?.widgetconfigs?.graphconfig) : []
        let widgetGraphSetting: WidgetGraphSetting[] = [];
        if(this.chartData) {
            if(this.chartData.columns && this.chartData.columns.length > 0) {
                this.chartData.columns.forEach((row : any, rowIndex: number) => {
                    let item: WidgetGraphSetting = { targetColumn: row[0], graphType: {value: 0, title: "棒"}, isSecondAxis: false, rowIndex};
                    widgetGraphSetting.push(item);
                });
            }     
        }   
     
        if(widgetGraphSetting.length) {
          if(this.graphConfig && this.graphConfig.length) {
            let deletedSetting = this.graphConfig.filter((item: any) => widgetGraphSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length == 0 );
            let addedSettings = widgetGraphSetting.filter((item: any) =>   !this.graphConfig.some(existingItem => existingItem.targetColumn === item.targetColumn) );
            let order = [...addedSettings, ...this.graphConfig];
            if(deletedSetting && deletedSetting.length) {
              order = order.filter((item: any) => deletedSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length >0 ? false :  true );
            }
    
            let settings = uniqBy(order, 'targetColumn');
            this.graphConfig = orderBy(settings, ["rowIndex"]);
          }
          else {
            let order = [...widgetGraphSetting];
            this.graphConfig = orderBy(order, ["rowIndex"]);
          }
        }
    }

    showDialogError() {
        let modalParam = new ModalParam();
        modalParam.description = COMMON_TEXT.ERROR_MESSAGE_TITLE_NOT_EXIST;
        let btnOk = new ButtonItem();
        btnOk.buttonText = COMMON_TEXT.YES;
        btnOk.buttonType = ButtonType.SECONDARY;
        modalParam.buttonArray = [btnOk];
        this.modalService.open(DialogModalComponent, {
          header: COMMON_TEXT.INFORMATION,
          width: '30em',
          data: { modalParam },
        });
    }

    setGraphConfig() {
        return this.graphConfig.length > 0
        ? this.graphConfig.map((config: any) => (
          { ...config, 
            isStackedChartSetting: this.isStackedChartSetting, 
            isKeepOriginTable: this.isKeepOriginTable,
            colSortState: this.colSortState
          }))
        : [{
              Checked: null, 
              GraphType: null, 
              IsSecondAxis: null, 
              RowIndex: null, 
              isStackedChartSetting: this.isStackedChartSetting, 
              isKeepOriginTable: this.isKeepOriginTable,
              colSortState: this.colSortState
        }];
    }

    // check switch column or row
    onClickSwitchButton(isTable: boolean = false) {
        // Call from Pivot-table chart switch state button
        if(isTable) {
           // Call from Pivot-table chart switch state button
           this.isKeepOriginTable = !this.isKeepOriginTable;
           this.isChangeOriginTable = true;
        }
        else
            this.isStackedChartSetting = !this.isStackedChartSetting;
        this.graphConfig = this.graphConfig || [];
        this.graphConfig = cloneDeep(this.setGraphConfig());
        this.widget.graphConfig = this.graphConfig;
        if(this.widgetResult) this.widgetResult.graphConfigs = this.graphConfig;
    }

    // set size for widget
    setWidgetSize() {
        let spliterData: any = (this.splitter as any)?.containerViewChild;
        let minHeight: number = 300;
        if (spliterData && spliterData.nativeElement) {
            const height = spliterData.nativeElement.clientHeight;
            const width = spliterData.nativeElement.clientWidth;
            if(this.panelSizes.length > 0) {
                this.widget.height = ((height / 100) * this.panelSizes[0]) - 100;
                this.widget.width = width - 50;
                let settingHeight = ((height / 100) * this.panelSizes[1]) + 50;
                this.displayChartSetting('end', settingHeight);
                this.panelBottomHeight = settingHeight < minHeight ? minHeight : settingHeight;
            }
        }
    }

    // resize panel
    async onResizePanel(event: any) {
        await this.getByWidgetCds(true);
        // min height tính theo %
        let minHeitht = 25;
        let sizes: any[] = event.sizes || [];
        this.panelSizes = sizes;
        this.setWidgetSize();
        this.isShowChartData = (sizes[0] < minHeitht) ? false :  true;       
        this.widget.isLoadWidgetResultComplete = true;
    }

    displayChartSetting(status: string, height: number) {
        let settingChart = document.getElementById('settingChart');
        if(settingChart) {
            settingChart.style.height = status == 'start' ? '0px' : (height > 50 ? 50 : 0) + 'px';
        }
    }

    getRequestBodyData() {
        this.filterCondition.isFullData = true;
		return {
			widgetCds: [this.widget.widgetcd],
			selectType: WidgetSelectDataType.FULLDATA,
			isTemplate: true,
			filterCondition: this.filterCondition,
            graphType: this.widget.type
		} as WidgetResultRequest
    }

    async getByWidgetCds(isResize: boolean = false) {
        this.filterCondition.isFullData = true;
		let requestBody = this.getRequestBodyData();

		let widgetResultData:  any[] = [];
		await this.widgetResultService.getByWidgetCds(requestBody)
		.then(res => {
			if (res.statuscode == 200) {
				widgetResultData = res.data || []; 
                this.tableData = { table: widgetResultData[0]?.result?.table, config: this.config }
                this.chartData = widgetResultData[0]?.result?.chart;
                this.widget.x = 0;
                this.widget.y = 0;
                this.widget.widgetConfig = this.widget.widgetconfigs; 
                if(this.widget.widgetConfig && !this.isChangeOriginTable)
                    this.isKeepOriginTable = readTableConfig(this.widget.widgetConfig);
                this.widget.widgetdetails = this.widget.widgetdetails;
                let widgetResult = widgetResultData.filter((x:any) => x.widgetcd == this.widget.widgetcd).pop();
                if(widgetResult?.result) {
                    if(!isResize) this.widgetResult = cloneDeep(widgetResult?.result);
                    this.widget.widgetResult = isResize? this.widgetResult: widgetResult?.result;
                    if(this.widgetResult?.graphConfigs?.length > 0) {
                        this.isStackedChartSetting = this.widgetResult?.graphConfigs[0].isStackedChartSetting || false;
                    }
                }
                this.widget = cloneDeep(this.widget);
			}
		});
    }

    showGraphsetting() {
        this.showGraphSetting.next();
    }

    // start thay đổi kích thước của widget và phần setting
    onResizeStart(event: any) {
        this.isShowChartData = false;
        this.displayChartSetting('start', 100);
    }

    // save chart config
    onSaveChartConfig(config: any) {
       if(!config.data) return;
       this.graphConfig = config.data;
       if(this.widgetResult) this.widgetResult.graphConfigs = this.graphConfig;
    }

    // insert data to table search log
    async insertSearchLog(inuse: boolean) {
		const processedSearchList = [this.widget?.name];

		if(processedSearchList.length) {
			await this.searchLogService.insert(processedSearchList, inuse);
		}
	}

    //Map total day
    async mapTotalDay(datasourceCd: string) {
        if(this.widget.widgetdetails?.length == 0) return;
        let columnList = await this.widgetService.getColumnList(datasourceCd);
        if(!columnList || columnList?.statuscode != 200) return;
        this.widget.widgetdetails = this.widget.widgetdetails.map((item: any) => {
            if(item.columnname.startsWith("TTD_") ) {
              item.delflg = true;
              return item;
            }
            let match = columnList.data?.filter((col: any) => item.columnname == col.columnname);
            if (match.length > 0) {
              let datatype = match[0].datatype;
              if (['EDATE', 'SDATE', 'R_SDATE', 'R_EDATE'].includes(match[0].columnname)) {
                datatype = DATATYPE.Date;
              }
              return { ...item, displayname: match[0].displayname, columnid: match[0].columnid, datatype: datatype };
            }
            return item;
           
          }).filter(Boolean) as WidgetDetail [];

          const hasTTD_WidgetDetails = cloneDeep(this.widget.widgetdetails?.filter((item: any )=> item.columnname.startsWith("TTD_") ));
          let ttdListItems = this.widget.widgetdetails?.filter((e: any) => e.columnname.startsWith("TTD_"));
          if(hasTTD_WidgetDetails.length) {
            //clone some attributes
            let ttdWidgetInfor = hasTTD_WidgetDetails[0];
            if(ttdWidgetInfor ) {
              let ttdDSSetting = columnList.data.filter((col:any) => col.columnname.startsWith("TTD_"));
              ttdDSSetting.forEach((item: any) => {

                let currentTTD = ttdListItems.filter((e: any) => e.columnname === item.columnname);

                ttdWidgetInfor.id = undefined;
                ttdWidgetInfor.datasourcecd = item.datasourcecd;
                ttdWidgetInfor.columnname = item.columnname;
                ttdWidgetInfor.displayname = item.displayname;
                ttdWidgetInfor.columnid = item.columnid;
                ttdWidgetInfor.columntype = currentTTD.length > 0 ? currentTTD[0].columntype : 0;
                ttdWidgetInfor.datatype = "INT";
                ttdWidgetInfor.delflg = false;
                ttdWidgetInfor.sortno = currentTTD.length > 0 ? currentTTD[0].sortno : 999;
                ttdWidgetInfor.pivotorder = currentTTD.length > 0 ? currentTTD[0].pivotorder : null;
                ttdWidgetInfor.utcsortno = currentTTD.length > 0 ? currentTTD[0].utcsortno : ttdWidgetInfor.utcsortno;
                ttdWidgetInfor.filtertype = currentTTD.length > 0 ? currentTTD[0].filtertype : undefined;
                ttdWidgetInfor.filtervalue = currentTTD.length > 0 ? currentTTD[0].filtervalue : undefined;
                ttdWidgetInfor.footertype = currentTTD.length > 0 ? currentTTD[0].footertype : undefined;
                ttdWidgetInfor.formattype = currentTTD.length > 0 ? currentTTD[0].formattype : undefined;
                ttdWidgetInfor.groupfilterval = currentTTD.length > 0 ? currentTTD[0].groupfilterval : undefined;
                ttdWidgetInfor.pivotfiltertype = currentTTD.length > 0 ? currentTTD[0].pivotfiltertype : undefined;
                ttdWidgetInfor.pivotfiltervalue = currentTTD.length > 0 ? currentTTD[0].pivotfiltervalue : undefined;
                ttdWidgetInfor.groupfilterval = currentTTD.length > 0 ? currentTTD[0].groupfilterval : undefined;
                ttdWidgetInfor.rounding = currentTTD.length > 0 ? currentTTD[0].rounding : undefined;
                ttdWidgetInfor.utcdelflg = currentTTD.length > 0 ? currentTTD[0].utcdelflg : undefined;
                ttdWidgetInfor.operator = currentTTD.length > 0 ? currentTTD[0].operator : undefined;
                this.widget.widgetdetails.push({...ttdWidgetInfor});
              } );
            }
        }
    }
}
