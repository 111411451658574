<div class="multiple-pannel">
  <div class="header-wrapper">
    <div class="action-wrapper left-action-wrapper">
      <pivot-text-button class="back-button" [iconType]="iconType.BACK" [buttonText]="BUTTON.RETURN" 
        (click)="backPage()" [buttonType]="buttonType.SECONDARY">
      </pivot-text-button>
      <div class="edit-name">
        <pivot-textbox [inputParams]="nameParams" (onFocusOut)="onInputName($event)" [inputValue]="name"></pivot-textbox>
      </div>
    </div>
    <div *ngIf="isDisplayToolTip" class="title-page {{nameParams.inputClass}}" > {{titlePage}} </div>
    <div *ngIf="!isDisplayToolTip" class="title-page"> {{titlePage}} </div>
    <div class="action-wrapper right-action-wrapper">
      <ng-content select="[top-buttons]"></ng-content>
      <i *ngIf="isDisplayControlIcon" id="control-icon"  class="pi pi-ellipsis-h control-icon"
        (click)="openControlPopup()"></i>
      <div id="control-popup" *ngIf="showControlPopup" class="control-popup" >
        <ng-content select="[control-popup]"></ng-content>
    </div>
    </div>
  </div>
  <div [ngClass]="isDisplayDSSetting? 'body-wrapper-ds-setting' :  'body-wrapper'">
    <div id="pannel-wrapper" *ngIf="isDisplayPannel" class="pannel " [ngClass]="{'expanded': isExpanded}">
      <div class="pannel-wrapper ">
        <div class="pannel-content-wrapper">
          <div *ngIf="dockerList.length" class="docker-wrapper">
            <div (click)="showDocker(docker.pannel)" *ngFor="let docker of dockerList" class="docker-item">
              <span class="bold-text">{{docker.name}}</span>
              <i class="pi pi-chevron-right docker-icon"></i>
            </div>
          </div>
          <div id="multiple-panel-template-body-left" class="pannel-content left-pannel" [ngClass]="{'hidden-pannel': !isDisplayLeftPannel}">
            <ng-content select="[left-pannel]"></ng-content>
          </div>
          <div class="pannel-content right-pannel" [ngClass]="{'hidden-pannel': !isDisplayRightPannel}">
            <ng-content select="[right-pannel]"></ng-content>
          </div>
        </div>
      </div>
    </div>
    <div id="content-wrapper" class="content-wrapper" [ngClass]="{'full-width': !isDisplayPannel}">
      <ng-content select="[body-content]"></ng-content>
    </div>
  </div>
</div>
