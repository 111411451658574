import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import StringUtils from 'src/app/util/stringUtils';
import { ConfirmDialogComponent } from '../../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { BUTTON, SYSTEM_SETTING } from '../../../../app/const/text-common';
import { ButtonIconType, ButtonType, DialogType, InputType } from '../../../../app/enum/common-enum';
import { ModalTemplate, ButtonItem, IconModal, ModalParam } from '../../../../app/models/common-model';
import { InviteStaffToPivotModel } from '../../../../app/models/response/notify.ro';
import { Staff } from '../../../../app/models/response/staff.ro';
import { ProcessLoadingService } from '../../../../app/services/loading.service';
import { NotifyService } from '../../../../app/services/modules/notify.service';
import Utils from '../../../../app/util/utils';
import { AuthenticationService } from '../../../services/authentication.service';
import { COMMON_TEXT } from '../../../../app/const/text-common';
import { DialogModalComponent } from '../../../../app/component/common/dialog-modal/dialog-modal.component';

@Component({
  selector: 'pivot-confirm-invite-dialog',
  templateUrl: './confirm-invite-dialog.component.html',
  styleUrls: ['./confirm-invite-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ConfirmInviteDialogComponent implements OnInit {
  @Input() listStaff: Staff[];
  @Input() lstPendingInvitation: any[] = [];
  @Input() isDisplayModal: boolean = false;
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Output() onSubmitData: any = new EventEmitter<any>();

  modalDlgDelete: ModalTemplate = new ModalTemplate();
  isDisplayConfirmDeleteModal: boolean = false;

  BUTTON = BUTTON;
  SYSTEM_SETTING = SYSTEM_SETTING;
  currentUserNm = '';

  buttonType = ButtonType;
  telArea: string = '';
  telCity: string = '';
  telNum: string = '';
  textVal: string = '';
  errorTelMsg: string = '';
  isMail: boolean = true;
  isPhone: boolean = false;
  isInvalidTelNum: boolean = false;

  inputNameParams: any = {
    type: InputType.text,
    validate: false,
    validateError: MESSAGE_TEXT.REQUIRE_FIELD,
    borderFill: true,
    disabled: false,
    isError: false,
    maxLength: 256
  };
  inputPhoneParams: any = [
    {
      type: InputType.number,
      validate: false,
      borderFill: true,
      disabled: false,
      isError: false,
      maxLength: 3,
    },
    {
      type: InputType.number,
      validate: false,
      borderFill: true,
      disabled: false,
      isError: false,
      maxLength: 4,
    },
    {
      type: InputType.number,
      validate: false,
      borderFill: true,
      disabled: false,
      isError: false,
      maxLength: 4,
    }
  ];
  iconType = ButtonIconType;

  private _notifyService: NotifyService;
  private _processLoadingService: ProcessLoadingService;
  private _modalService: DialogService;
  constructor(
    private notifyService: NotifyService,
    private processLoadingService: ProcessLoadingService,
    private modalService: DialogService,
    private authenticationService: AuthenticationService,
    ) {

    this.authenticationService.currentUser.subscribe((user: any) => {
      if (user && user.token) {
        this.currentUserNm = user.fullname;
      }
    });

    this._notifyService = notifyService;
    this._processLoadingService = processLoadingService;
    this._modalService = modalService;
  }

  ngOnInit(): void {
    this.isMail = true;
    this.isPhone = false;
    this.inputNameParams.disabled = false;
    this.inputPhoneParams.forEach((element: any) => {
      element.disabled = true;
    });
  }

  onClose() {
    this.isDisplayModal = false;
    this.onSubmitData.emit(null);
    this.onRefreshDialog();
  }

  async onSubmit() {
    if (this.isMail) {
      if (this.onValidateEmail()) {
        this._processLoadingService.isLoading.emit(true);
        let model = new InviteStaffToPivotModel();
        model.type = 'mail';
        model.mail = this.textVal;
        model.staffnm = this.currentUserNm;
        let res = await this._notifyService.inviteStaffsToPivot(model);
        if (res.statuscode == 200 && res.data.result) {
          // this.onSubmitData.emit({ type: model.type, value: this.textVal });
          this.lstPendingInvitation = await this.notifyService.getPendingInvitation();
          this.onRefreshDialog();
          this._processLoadingService.isLoading.emit(false);
        } else {
          this._processLoadingService.isLoading.emit(false);
          this.openDialogSuccess(DialogType.error);
        }
      };
    }

    if (this.isPhone) {
      if (this.onValidateTel()) {
        this._processLoadingService.isLoading.emit(true);
        let model = new InviteStaffToPivotModel();
        model.type = 'phone';
        model.phone = this.telArea + '' + this.telCity + '' + this.telNum;
        model.staffnm = this.currentUserNm;
        let res = await this._notifyService.inviteStaffsToPivot(model);
        if (res.statuscode == 200) {
          // this.onSubmitData.emit({ type: model.type, value: model.phone });
          this.lstPendingInvitation = await this.notifyService.getPendingInvitation();
          this.onRefreshDialog();
          this._processLoadingService.isLoading.emit(false);
        } else {
          this._processLoadingService.isLoading.emit(false);
          this.openDialogSuccess(DialogType.error);
        }
      };
    }
    this.inputNameParams = JSON.parse(JSON.stringify(this.inputNameParams));
    this.inputPhoneParams = JSON.parse(JSON.stringify(this.inputPhoneParams));
  }

  onCheckbox(type: string) {
    this.isInvalidTelNum = false;
    this.errorTelMsg = "";
    if (type == 'phone') {
      this.isPhone = true;
      this.isMail = false;
      this.telArea = '';
      this.telCity = '';
      this.telNum = '';
      this.textVal = '';
      this.inputNameParams.disabled = true;
      this.inputNameParams.isValidate = false;
      this.inputNameParams.isError = false;
      this.inputPhoneParams.forEach((element: any) => {
        element.disabled = false;
        element.isValidate = false;
        element.isError = false;
      });
    } else {
      this.isMail = true;
      this.isPhone = false;
      this.telArea = '';
      this.telCity = '';
      this.telNum = '';
      this.textVal = '';
      this.inputNameParams.disabled = false;
      this.inputNameParams.isValidate = false;
      this.inputNameParams.isError = false;
      this.inputPhoneParams.forEach((element: any) => {
        element.disabled = true;
        element.isValidate = false;
        element.isError = false;
      });
    }
    this.inputNameParams = cloneDeep(this.inputNameParams);
    this.inputPhoneParams = cloneDeep(this.inputPhoneParams);
  }

  onRefreshDialog() {
    // this.isDisplayModal = false;
    this.isMail = true;
    this.isPhone = false;
    this.telArea = '';
    this.telCity = '';
    this.telNum = '';
    this.textVal = '';
    this.inputNameParams.disabled = false;
    this.inputPhoneParams[0].disabled = false;
    this.inputPhoneParams[1].disabled = false;
    this.inputPhoneParams[2].disabled = false;
    this.isInvalidTelNum = false;
    this.errorTelMsg = "";
    // this.lstPendingInvitation = [];
    this.onCheckbox('email');
  }

  onValidateEmail() {
    if (Utils.isNullOrEmpty(this.textVal)) {
      this.inputNameParams.isError = true;
      this.inputNameParams.validate = true;
      this.inputNameParams.validateError = MESSAGE_TEXT.REQUIRE_FIELD;
      return false;
    } else if (!(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.textVal))) {
      this.inputNameParams.isError = true;
      this.inputNameParams.validate = true;
      this.inputNameParams.validateError = MESSAGE_TEXT.INCORRECT_MAIL_FORMAT;
      return false;
    } else if (this.listStaff.filter(s => s.email == this.textVal).length > 0) {
      this.inputNameParams.isError = true;
      this.inputNameParams.validate = true;
      this.inputNameParams.validateError = MESSAGE_TEXT.DUPLICATE_VALIDATE;
      return false;
    } else {
      this.inputNameParams.isError = false;
      this.inputNameParams.validate = false;
      return true;
    }
  }

  onInput(event: any, type: string) {
    switch (type) {
      case 'area':
        if (StringUtils.checkValidateInput(1, event.data) && StringUtils.checkValidateInput(this.inputPhoneParams[0].maxLength, event.target.value)) {
          this.telArea = event.target.value;
        } else {
          event.target.value = this.telArea;
        }

        break;
      case 'city':
        if (StringUtils.checkValidateInput(1, event.data) && StringUtils.checkValidateInput(this.inputPhoneParams[1].maxLength, event.target.value)) {
          this.telCity = event.target.value;
        } else {
          event.target.value = this.telCity;
        }
        
        break;
      case 'num':
        if (StringUtils.checkValidateInput(1, event.data) && StringUtils.checkValidateInput(this.inputPhoneParams[2].maxLength, event.target.value)) {
          this.telNum = event.target.value;
        } else {
          event.target.value = this.telNum;
        }

        break;
      case 'email':
        const trimmed = event.target.value.trim();
        if (
          (event.inputType === "insertFromPaste" && StringUtils.checkValidateInput(trimmed.length, trimmed) 
          || StringUtils.checkValidateInput(1, event.data)) 
          && StringUtils.checkValidateInput(this.inputNameParams.maxLength, trimmed)
        ) {
          this.textVal = trimmed;
        } else {
          event.target.value = this.textVal;
        }

        break;

      default:
        break;
    }
  }

  onKeydownInput(event: any, type: string) {
    const invalidChars = [
      '-',
      '+',
      'e',
      'w',
      's'
    ];

    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  onValidateTel() {
    let telNumber = this.telArea + '' + this.telCity + '' + this.telNum;
    if (this.telArea == '' || this.telCity == '' || this.telNum == '') {
      if (this.telArea == '') {
        this.inputPhoneParams[0].isValidate = true;
        this.inputPhoneParams[0].isError = true;
        this.inputPhoneParams[0].validate = true;
      }
      if (this.telCity == '') {
        this.inputPhoneParams[1].isValidate = true;
        this.inputPhoneParams[1].isError = true;
        this.inputPhoneParams[1].validate = true;
      }
      if (this.telNum == '') {
        this.inputPhoneParams[2].isValidate = true;
        this.inputPhoneParams[2].isError = true;
        this.inputPhoneParams[2].validate = true;
      }
      this.isInvalidTelNum = true;
      this.errorTelMsg = MESSAGE_TEXT.REQUIRE_FIELD;
      return false;
    } else if (this.listStaff.filter(s => s.phone == telNumber).length > 0) {
      this.inputPhoneParams.forEach((element: any) => {
        element.isValidate = true;
        element.isError = true;
        element.validate = true;
      });
      this.isInvalidTelNum = true;
      this.errorTelMsg = MESSAGE_TEXT.DUPLICATE_VALIDATE;
      return false;
    } else {
      this.inputPhoneParams.forEach((element: any) => {
        element.isValidate = false;
        element.isError = false;
      });
      return true;
    }
  }

  onFocusTel() {
    this.isInvalidTelNum = false;
    this.errorTelMsg = "";
    this.inputPhoneParams.forEach((element: any) => {
      element.isValidate = false;
      element.isError = false;
      element.validate = false;
    });
    this.inputPhoneParams = cloneDeep(this.inputPhoneParams);
  }

  onFocusMail() {
    this.inputNameParams.validate = false;
    this.inputNameParams.isError = false;
    this.inputNameParams.isValidate = false;
    this.inputNameParams = cloneDeep(this.inputNameParams);
  }

  openDialogSuccess(dialogType: DialogType, message: any = null) {
    this._modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
        message: message
      }
    });
  }

  onChangePhoneNumber1() {
    if (this.telArea && this.telArea.length === 3) {
      const inpPhoneNumber2Id = document.getElementById('inpPhoneNumber2');
      if (inpPhoneNumber2Id) {
        inpPhoneNumber2Id.focus();
      }
    }
  }

  onChangePhoneNumber2() {
    if (this.telCity && this.telCity.length === 4) {
      const inpPhoneNumber3Id = document.getElementById('inpPhoneNumber3');
      if (inpPhoneNumber3Id) {
        inpPhoneNumber3Id.focus();
      }
    }
  }

  mouseEnterRemovePendingBtn(pendingId: string) {
    const indx = [...this.lstPendingInvitation].findIndex((pendingEl: any) => pendingEl.id === pendingId);
    if (indx !== -1) {
      this.lstPendingInvitation[indx].isHoverRemovePendingBtn = true;
    }
  }

  mouseLeaveRemovePendingBtn(pendingId: string) {
    const indx = [...this.lstPendingInvitation].findIndex((pendingEl: any) => pendingEl.id === pendingId);
    if (indx !== -1) {
      this.lstPendingInvitation[indx].isHoverRemovePendingBtn = false;
    }
  }

  showDlgConfigmRemovePending(pendingId: string) {
    let modalParam = new ModalParam();
    modalParam.description = MESSAGE_TEXT.CONFIRM_DELETE_PENDING_INVITATION_STAFF;
    let icon = new IconModal();
    icon.iconName = "pi-question";
    icon.iconColor = "#0073BA";
    icon.isSmallSize = false;
    modalParam.icon = icon;
    let btnOk = new ButtonItem();
    btnOk.buttonText = COMMON_TEXT.YES;
    btnOk.buttonType = ButtonType.SECONDARY;
    btnOk.clickFunction = async () => {
      this.onConfirmDeleteDlg(pendingId);
    }
    let btnCancel = new ButtonItem();
    btnCancel.buttonText = COMMON_TEXT.NO;
    btnCancel.buttonType = ButtonType.SECONDARY;
    modalParam.buttonArray = [btnOk, btnCancel];
    this.modalService.open(DialogModalComponent, {
      header: COMMON_TEXT.CONFIRM,
      width: '30em',
      data: { modalParam },
    });

  }

  async onConfirmDeleteDlg(pendingId: string) {
    this._processLoadingService.isLoading.emit(true);
    let userPending = [...this.lstPendingInvitation].find((el: any) => el.id === pendingId);
    let delRes = await this.notifyService.deletePendingInvitation(pendingId, userPending?.user);
    if (delRes && delRes.statuscode === 200) {
      this.lstPendingInvitation = [...this.lstPendingInvitation].filter((el: any) => el.id !== pendingId);
      this._processLoadingService.isLoading.emit(false);
      this.openDialogSuccess(DialogType.delete, MESSAGE_TEXT.CANCEL_COMPLETE); 
    } else {
      this._processLoadingService.isLoading.emit(false);
      this.openDialogSuccess(DialogType.error);
    }
  }
}
